import React from "react"

declare global {
  interface Window {
    Sentry: {
      withScope: typeof import("@sentry/minimal").withScope
      captureException: typeof import("@sentry/minimal").captureException
      showReportDialog: typeof import("@sentry/browser").showReportDialog
    }
  }
}

export const wrapRootElement = ({ element }: { element: JSX.Element }) => {
  if (process.env.NODE_ENV != "production") return element

  return <ErrorBoundary>{element}</ErrorBoundary>
}

const errorHandler = (error: Error | null, componentStack: string) => {
  window.Sentry.withScope((scope) => {
    scope.setExtra("componentStack", componentStack)
    window.Sentry.captureException(error)
  })
}

export const ErrorBoundaryFallbackComponent = () => (
  <div>
    <h1>An error has occurred.</h1>
    <a onClick={() => window.Sentry.showReportDialog()}>Report feedback</a>
  </div>
)

interface ErrorState {
  error: Error | null
  info: React.ErrorInfo | null
}

class ErrorBoundary extends React.Component<unknown, ErrorState> {
  public state = {
    error: null,
    info: null,
  }

  public componentDidCatch(error: Error | null, info: React.ErrorInfo) {
    try {
      errorHandler.call(this, error, info ? info.componentStack : "")
      //eslint-disable-next-line no-empty
    } catch (ignoredError) {}

    this.setState({ error, info })
  }

  public render() {
    const { children } = this.props
    const { error } = this.state

    if (error !== null) return <ErrorBoundaryFallbackComponent />

    return children
  }
}
