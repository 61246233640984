import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { CSSTransition } from "react-transition-group"
import { HeaderQuery } from "../generated/graphql"
import idx from "idx"
import classnames from "classnames"
import SEO from "./seo"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const flatten = (array: any[]) =>
  array.reduce((list, item) => list.concat(item), [])

const LinkLI = ({ children }: { children: React.ReactNode }) => (
  <li className="sm:block text-xl lg:text-lg lg:inline-block lg:float-left lg:ml-8 first:ml-0">
    {children}
  </li>
)

const NavLink = ({
  to: to,
  text: text,
  handleClick: handleClick = () => {
    null
  },
}: {
  to: string
  text: string
  handleClick?: () => void
}) => {
  return (
    <LinkLI>
      <span onClick={handleClick}>
        <Link
          className="transition-slow transition-all"
          to={to}
          activeClassName="text-bm-brown"
        >
          {text}
        </Link>
      </span>
    </LinkLI>
  )
}

const Header = ({ siteTitle }: { siteTitle: string }) => {
  const [state, setState] = useState({
    menuOpen: false,
    subMenuOpen: false,
  })
  const { menuOpen, subMenuOpen } = state

  const data: HeaderQuery = useStaticQuery(graphql`
    query Header {
      leftImage: file(relativePath: { eq: "left.png" }) {
        childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      rightImage: file(relativePath: { eq: "right.png" }) {
        childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      galleryPages: allMarkdownRemark(
        filter: {
          frontmatter: { template: { eq: "gallery_page" }, draft: { ne: true } }
        }
        sort: { fields: [frontmatter___slug], order: ASC }
      ) {
        nodes {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  `)

  const galleryPages = idx(data, (_) => _.galleryPages.nodes) || []
  const leftImage = idx(data, (_) => _.leftImage.childImageSharp.fixed.src)
  const rightImage = idx(data, (_) => _.rightImage.childImageSharp.fixed.src)

  const subMenuLinks = galleryPages.map((node, i: number) => {
    const slug = idx(node, (_) => _.frontmatter.slug)
    const title = idx(node, (_) => _.frontmatter.title)
    if (!(slug && title)) return <div />

    return <NavLink key={i} to={`/${slug}`} text={title} />
  })

  const closeSubMenu = () => setState({ ...state, subMenuOpen: false })

  const links = flatten([
    <NavLink key={1} to="/" text="Home" handleClick={closeSubMenu} />,
    <NavLink
      key={2}
      to="/about-the-artist"
      text="About"
      handleClick={closeSubMenu}
    />,
    <LinkLI key={3}>
      <span
        onClick={(e) => {
          e.preventDefault()
          setState({ ...state, subMenuOpen: !subMenuOpen })
        }}
      >
        <a href="#" className="transition z-10">
          Art
        </a>
      </span>
      <CSSTransition
        key={4}
        in={subMenuOpen}
        timeout={{ enter: 500, exit: 500 }}
      >
        {(state) => (
          <nav
            className={classnames(
              state == "exited" && "max-h-0",
              state == "entering" && "max-h-500 border-t-2 border-gray-400",
              state == "entered" && "max-h-500 border-t-2 border-gray-400",
              state == "exiting" && "max-h-0",
              "lg:hidden w-full overflow-hidden transition-slow transition-height text-center z-10 relative bg-gray-200"
            )}
          >
            <ul className="w-auto m-auto xl:px-8 sm:block lg:inline-block">
              {subMenuLinks}
            </ul>
          </nav>
        )}
      </CSSTransition>
    </LinkLI>,
    <NavLink
      key={5}
      to="/in-the-studio"
      text="In The Studio"
      handleClick={closeSubMenu}
    />,
    <NavLink
      key={6}
      to="/purchasing-faq"
      text="Purchasing FAQ"
      handleClick={closeSubMenu}
    />,
    <NavLink key={7} to="/basket" text="Basket" handleClick={closeSubMenu} />,
    <NavLink key={8} to="/contact" text="Contact" handleClick={closeSubMenu} />,
  ])

  return (
    <header className="relative">
      <SEO />
      <div
        style={{
          backgroundImage: `url(${leftImage}), url(${rightImage})`,
        }}
        className="absolute top-0 left-0 w-full bg-contain bg-no-repeat bg-header h-16 sm:h-24"
      />
      <h1 className="text-center m-0 leading-tight z-10 relative">
        <Link
          className="font-fancy text-5xl text-bm-blue-500 text-shadow"
          to="/"
        >
          {siteTitle}
        </Link>
      </h1>
      <h2 className="text-center m-0 mb-1 leading-tight z-10 relative">
        <Link
          className="font-fancy text-3xl text-bm-blue-500 text-shadow"
          to="/"
        >
          Natural Art & Design
        </Link>
      </h2>

      <CSSTransition in={menuOpen} timeout={{ enter: 500, exit: 500 }}>
        {(state) => (
          <nav
            className={classnames(
              state == "exited" && "max-h-0",
              state == "entering" && "max-h-500",
              state == "entered" && "max-h-500",
              state == "exiting" && "max-h-0",
              "lg:max-h-500 block lg:inline-block w-full overflow-hidden transition-slow transition-height text-center z-10 relative bg-white-op"
            )}
          >
            <ul className="w-auto m-auto xl:px-8 sm:block lg:inline-block">
              {links}
            </ul>
          </nav>
        )}
      </CSSTransition>
      <CSSTransition in={subMenuOpen} timeout={{ enter: 500, exit: 500 }}>
        {(state) => (
          <nav
            className={classnames(
              state == "exited" && "max-h-0",
              state == "entering" && "max-h-500 border-t-2 border-gray-400",
              state == "entered" && "max-h-500 border-t-2 border-gray-400",
              state == "exiting" && "max-h-0",
              "hidden lg:inline-block w-full mb-2 overflow-hidden transition-slow transition-height text-center z-10 relative bg-gray-200"
            )}
          >
            <ul className="w-auto m-auto xl:px-8 sm:block lg:inline-block">
              {subMenuLinks}
            </ul>
          </nav>
        )}
      </CSSTransition>
      <div className="lg:hidden text-center mb-8">
        <a
          className={classnames(
            "text-xl transition z-10 relative bg-white-op border-b-2 border-gray-400 px-12",
            menuOpen
              ? "text-bm-blue-500 hover:text-bm-blue-500 border-t-2"
              : "text-bm-blue-700 hover:text-bm-blue-700"
          )}
          onClick={(e) => {
            e.preventDefault()
            setState({ ...state, menuOpen: !menuOpen })
          }}
        >
          Menu
        </a>
      </div>
    </header>
  )
}

export default Header
