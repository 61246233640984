import "../css/global.css"
import Header from "./header"
import React, { ReactNode } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ReactComponent as Facebook } from "../images/facebook.svg"
import { ReactComponent as Instagram } from "../images/instagram.svg"
import { ReactComponent as Etsy } from "../images/etsy.svg"
import { ReactComponent as Logo } from "../images/logo.svg"
import classnames from "classnames"
import { LayoutQuery } from "../generated/graphql"
import idx from "idx"

const SocialLinks = ({
  className,
  iconClassName: iconClassNameProp,
}: {
  className: string
  iconClassName?: string
}) => {
  const iconClassName = iconClassNameProp || ""
  const linkClassName =
    "mx-4 text-bm-blue-500 hover:text-bm-blue-500 leading-icon"
  return (
    <div className={classnames("mt-2", className)}>
      <a
        href="https://facebook.com/judithutter.naturalscienceart"
        className={linkClassName}
      >
        <Facebook
          width={28}
          height={28}
          className={classnames(iconClassName, "inline")}
        />
      </a>
      <a
        href="https://www.instagram.com/judithutterart/"
        className={linkClassName}
      >
        <Instagram
          width={28}
          height={28}
          className={classnames(iconClassName, "inline")}
        />
      </a>
      <a
        href="https://www.etsy.com/shop/BotanicalArtGallery"
        className={linkClassName}
      >
        <Etsy
          width={28}
          height={28}
          className={classnames(iconClassName, "inline")}
        />
      </a>
    </div>
  )
}

const Layout = ({
  children,
  title,
}: {
  children: ReactNode
  title?: string
}) => {
  const data: LayoutQuery = useStaticQuery(graphql`
    query Layout {
      siteTitle: site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle =
    title || idx(data, (_) => _.siteTitle.siteMetadata.title) || ""

  return (
    <div className="min-h-screen" style={{ backgroundColor: "#EBF5FB" }}>
      <div className="xl:my-1 mx-auto max-w-6xl xl:border-2 border-gray-200 bg-white">
        <Header siteTitle={siteTitle} />
        <main className="px-4 flex-1">{children}</main>
        <footer className="mt-10 mb-2 px-2 text-center w-full border-box">
          <Logo width={180} height={180} className="inline shadow-logo" />
          <SocialLinks className="my-4 flex flex-row flex-wrap justify-center" />
          ©{new Date().getFullYear()} Judith Utter Natural Art and Design. All
          Rights Reserved.
        </footer>
      </div>
    </div>
  )
}

export default Layout
