/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { SeoQuery } from "../generated/graphql"
import idx from "idx"

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
}: {
  description: string
  lang: string
  meta: [
    | {
        name: string
        content: string
        property?: undefined
      }
    | {
        property: string
        content: string
        name?: undefined
      }
  ]
  keywords: string[]
  title?: string
}) {
  const data: SeoQuery = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            subtitle
            description
          }
        }
      }
    `
  )

  const { site } = data
  const siteTitle = idx(site, (_) => _.siteMetadata.title)

  const metaDescription =
    description || idx(site, (_) => _.siteMetadata.description)
  const fullTitle =
    title || [title, idx(site, (_) => _.siteMetadata.subtitle)].join(" ")

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={fullTitle}
      titleTemplate={title ? `%s | ${siteTitle}` : fullTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription || "",
        },
        {
          property: `og:title`,
          content: title || "",
        },
        {
          property: `og:description`,
          content: metaDescription || "",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title || "",
        },
        {
          name: `twitter:description`,
          content: metaDescription || "",
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

export default SEO
