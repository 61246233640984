// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-basket-tsx": () => import("./../../../src/pages/basket.tsx" /* webpackChunkName: "component---src-pages-basket-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-gallery-page-template-tsx": () => import("./../../../src/templates/gallery_page-template.tsx" /* webpackChunkName: "component---src-templates-gallery-page-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/index-template.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-item-template-tsx": () => import("./../../../src/templates/item-template.tsx" /* webpackChunkName: "component---src-templates-item-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

